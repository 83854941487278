import React, { useEffect, useRef, useState } from "react";
// import gsap from "gsap";
// import { EffectComposer, Pixelation } from "@react-three/postprocessing";
import {
	useGLTF,
	MeshTransmissionMaterial,
	Center,
	Environment,
	Float,
	PerspectiveCamera,
	// OrbitControls,
} from "@react-three/drei";
import { Color, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import TDTypo from "./TDTypo";
import Box from "./Box";

function SlimeCube() {
	const [samples, setSamples] = useState(32);
	const [res, setRes] = useState(2048);
	const [fov, setFov] = useState(65);

	const SlimeRef = useRef();
	// const { camera, mouse } = useThree()
	const vec = new Vector3();

	useFrame(({ camera, mouse }) => {
		camera.position.lerp(vec.set(mouse.x, mouse.y, camera.position.z), 0.05);
		camera.lookAt(new Vector3(0, 0, 0));
		// SlimeRef.current.rotation.x += 0.00001;
		SlimeRef.current.rotation.y += 0.001;
	});
	const { nodes } = useGLTF("/gelatinous_cube-transformed.glb");
	useEffect(() => {
		const width = window.innerWidth;
		if (width <= 425) {
			setSamples(1);
			setRes(256);
			setFov(100);
		}
	}, []);

	return (
		<>
			<Environment
				files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/dancing_hall_1k.hdr"
				// blur={1}
			/>
			<PerspectiveCamera
				position={[0, 0, 10]}
				fov={fov}
				makeDefault
				setFocalLength={135}
			/>
			{/* <OrbitControls /> */}
			<group
				ref={SlimeRef}
				position={[0, 0, 3]}
			>
				<Center center>
					<group dispose={null}>
						<mesh
							geometry={nodes.cube1.geometry}
							position={[-0.56, 0, -0.11]}
						>
							<MeshTransmissionMaterial
								// background={new Color(config.bg)}
								// {...config}
								transmissionSampler={true}
								// backside={false}
								samples={samples}
								resolution={res}
								transmission={1}
								roughness={0}
								thickness={1.36}
								ior={1.37}
								chromaticAberration={0.5}
								anisotropy={0.2}
								distortion={0.49}
								distortionScale={0.48}
								temporalDistortion={0.11}
								clearcoat={0.76}
								attenuationDistance={10}
								attenuationColor={"#ffffff"}
								color={"#ffffff"}
								background={new Color("#ffffff")}
							/>
						</mesh>
					</group>
				</Center>

				{letters.map((letter, index) => {
					if (letter.float) {
						return (
							<Float
								key={index}
								speed={2} // Animation speed, defaults to 1
								rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
								floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
								floatingRange={[0.1, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
							>
								<TDTypo
									letter={letter.letter}
									position={letter.position}
									scale={letter.scale}
									rotationx={letter.rx}
									rotationy={letter.ry}
									rotationz={letter.rz}
									color={"0000ff"}
								/>
							</Float>
						);
					}
					if (!letter.float) {
						return (
							<TDTypo
								key={index}
								letter={letter.letter}
								position={letter.position}
								scale={letter.scale}
								rotationx={letter.rx}
								rotationy={letter.ry}
								rotationz={letter.rz}
								col={"ffffff"}
							/>
						);
					}
					return null;
				})}
			</group>

			{[...Array(11).keys()].map((i) => (
				<group key={i * 9}>
					<Box
						position={[-9, -5.5 + i * 1.1, 0]}
						text={"N"}
					/>
					<Box
						position={[-8, -5.5 + i * 1.1, 0]}
						text={"O"}
					/>
					<Box
						position={[-7, -5.5 + i * 1.1, 0]}
						text={"I"}
					/>
					<Box
						position={[-6, -5.5 + i * 1.1, 0]}
						text={"R"}
					/>
					<Box
						position={[-5, -5.5 + i * 1.1, 0]}
						text={"P"}
					/>
					<Box
						position={[-4, -5.5 + i * 1.1, 0]}
						text={"R"}
					/>
					<Box
						position={[-3, -5.5 + i * 1.1, 0]}
						text={"O"}
					/>
					<Box
						position={[-2, -5.5 + i * 1.1, 0]}
						text={"N"}
					/>
					<Box
						position={[-1, -5.5 + i * 1.1, 0]}
						text={"O"}
					/>
					<Box
						position={[0, -5.5 + i * 1.1, 0]}
						text={"I"}
					/>
					<Box
						position={[1, -5.5 + i * 1.1, 0]}
						text={"R"}
					/>
					<Box
						position={[2, -5.5 + i * 1.1, 0]}
						text={"P"}
					/>
					<Box
						position={[3, -5.5 + i * 1.1, 0]}
						text={"R"}
					/>
					<Box
						position={[4, -5.5 + i * 1.1, 0]}
						text={"O"}
					/>
					<Box
						position={[5, -5.5 + i * 1.1, 0]}
						text={"N"}
					/>
					<Box
						position={[6, -5.5 + i * 1.1, 0]}
						text={"O"}
					/>
					<Box
						position={[7, -5.5 + i * 1.1, 0]}
						text={"I"}
					/>
					<Box
						position={[8, -5.5 + i * 1.1, 0]}
						text={"R"}
					/>
				</group>
			))}
		</>
	);
}

export default SlimeCube;
const letters = [
	{
		letter: "S",
		position: [-1.5, -1, 0],
		scale: [5, 5, 5],
		rx: Math.PI / 16,
		ry: 0,
		rz: Math.PI / 16,
		float: false,
	},
	{
		letter: "S",
		position: [-0.5, -1.5, -1],
		scale: [5, 5, 5],
		rx: Math.PI / 16,
		ry: 0,
		rz: Math.PI / 16,
		float: false,
	},
	{
		letter: "A",
		position: [-1, 2.3, 0],
		scale: [3, 3, 3],
		rx: Math.PI / 2,
		ry: Math.PI / 3,
		rz: Math.PI / 4,
		float: true,
	},
	{
		letter: "B",
		position: [2, 2, 0],
		scale: [3, 3, 3],
		rx: Math.PI / 2,
		ry: Math.PI / 3,
		rz: Math.PI / 4,
		float: true,
	},
	{
		letter: "C",
		position: [1, 1, -2],
		scale: [2, 2, 2],
		rx: Math.PI / 2,
		ry: -Math.PI / 3,
		rz: Math.PI / 4,
		float: false,
	},
	{
		letter: "D",
		position: [-2, -1, -2],
		scale: [2, 2, 2],
		rx: Math.PI / 2,
		ry: -Math.PI / 3,
		rz: Math.PI / 4,
		float: true,
	},
	{
		letter: "E",
		position: [-1, -2, -2],
		scale: [2, 2, 2],
		rx: Math.PI / 3,
		ry: -Math.PI / 6,
		rz: Math.PI / 4,
		float: false,
	},
	{
		letter: "F",
		position: [-1, 1, -1.6],
		scale: [2, 2, 2],
		rx: -Math.PI / 3,
		ry: Math.PI / 2,
		rz: 0,
		float: true,
	},
	{
		letter: ";",
		position: [-1, 1, 1],
		scale: [1, 1, 1],
		rx: 0,
		ry: 0,
		rz: 0,
		float: true,
	},
	{
		letter: "<",
		position: [1, 1, 1],
		scale: [1, 1, 1],
		rx: 0,
		ry: 0,
		rz: 0,
		float: true,
	},
];
