import React from "react";
import { useFrame, useThree } from "@react-three/fiber";
// import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import { Physics, usePlane, useSphere } from "@react-three/cannon";
import TDTypo from "./TDTypo";
import { EffectComposer, DotScreen } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
// import { Perf } from "r3f-perf";

const InstancedSpheres = () => {
	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
	];

	return (
		<>
			{alphabet.map((letter, index) => {
				return (
					<TDTypo
						key={index}
						letter={letter}
					/>
				);
			})}
		</>
	);
};

const Borders = () => {
	const { viewport } = useThree();
	return (
		<>
			<Plane
				position={[0, -viewport.height, 0]}
				rotation={[-Math.PI / 2, 0, 0]}
			/>
			<Plane
				position={[-viewport.width - 1, 0, 0]}
				rotation={[0, Math.PI / 2, 0]}
			/>
			<Plane
				position={[viewport.width + 1, 0, 0]}
				rotation={[0, -Math.PI / 2, 0]}
			/>
			<Plane
				position={[0, 0, 1]}
				rotation={[0, 0, 0]}
			/>
			<Plane
				position={[0, 0, 12]}
				rotation={[0, -Math.PI, 0]}
			/>
		</>
	);
};

const Plane = ({ color, ...props }) => {
	usePlane(() => ({ ...props }));
	return null;
};

function Mouse() {
	const { viewport } = useThree();
	const [, api] = useSphere(() => ({ type: "Kinematic", args: [3] }));
	return useFrame((state) =>
		api.position.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 7)
	);
}

function MouseMove() {
	return (
		<>
			<fog
				attach="fog"
				args={["#000000", 20, 30]}
			/>
			<color
				attach="background"
				args={["#000000"]}
			/>
			{/* <Perf /> */}
			<EffectComposer>
				<DotScreen
					blendFunction={BlendFunction.LINEAR_BURN} // blend mode
					angle={Math.PI * 0.5} // angle of the dot pattern
					scale={1.2} // scale of the dot pattern
				/>
			</EffectComposer>
			<ambientLight intensity={1.5} />
			<directionalLight
				position={[-10, -10, -5]}
				intensity={0.5}
			/>
			{/* <AsciiRenderer
				resolution={0.1}
				characters=" .:-+* %@#"
			/> */}
			<directionalLight
				castShadow
				intensity={6}
				position={[50, 50, 25]}
				shadow-mapSize={[256, 256]}
				shadow-camera-left={-10}
				shadow-camera-right={10}
				shadow-camera-top={10}
				shadow-camera-bottom={-10}
			/>
			<Suspense fallback={null}>
				<Physics
					gravity={[0, -50, 0]}
					defaultContactMaterial={{ restitution: 0.5 }}
				>
					<group position={[0, 0, -10]}>
						<InstancedSpheres />
						<Borders />
						<Mouse />
					</group>
				</Physics>
			</Suspense>
			{/* <OrbitControls /> */}
		</>
	);
}

export default MouseMove;
