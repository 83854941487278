import React, { useContext, useState } from "react";
import "./Header.scss";
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight";
import { MyContext } from "../../App";
import { Link } from "react-router-dom";
// import Box from "@mui/material/Box";
// import { Modal } from "@mui/material";
import OffCanvas from "./OffCanvas";
import Navi from "./Navi";

function Header() {
	const { /*theme,*/ handleThemeLight, handleThemeDark } = useContext(MyContext);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<div id="headerMain">
				<Link
					to="/"
					id="logo"
				></Link>
				<h3>
					<BsArrowReturnRight className="Arrow" />
					TYPEFACES
				</h3>
				<div id="fontsList">
					<Navi />
				</div>
				{/* <h3>
					<BsArrowReturnRight className="Arrow" />
					THEME
				</h3> */}
				{/* <div className="btns">
					<button
						className="lightBtn"
						onClick={handleThemeLight}
					></button>
					<button
						className="darkBtn"
						onClick={handleThemeDark}
					></button>
				</div> */}
			</div>
			<div id="headerMainMob">
				<button
					id="mainBtn"
					onClick={handleOpen}
				></button>
				<OffCanvas
					open={open}
					handleClose={handleClose}
				/>
			</div>
		</>
	);
}

export default Header;
