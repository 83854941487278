import React, { useEffect, useRef, useState } from "react";
import "./FontCard2.scss";
import { FaAlignCenter, FaAlignLeft } from "react-icons/fa";
import { AiOutlineArrowsAlt } from "react-icons/ai";
import Slider from "@mui/material/Slider";

function FontCard2(props) {
	const [Cap, setCap] = useState(true);
	const [vari, setVari] = useState(100);
	const [value, setValue] = useState(props.defaultSize);

	const CapBtn = useRef();
	const NonCapBtn = useRef();
	const textRef = useRef();
	const CardRef = useRef();

	const handleCapBtn = () => {
		setCap(true);
		// textRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive; font-size:${value}px; `;
	};
	const handleNonCapBtn = () => {
		setCap(false);
	};
	useEffect(() => {
		if (Cap) {
			textRef.current.style = `text-transform: uppercase;font-size:${value}px;font-weight:${vari};`;
			CapBtn.current.style = "color:white;";
			NonCapBtn.current.style = "color:grey; text-decoration:underline;";
		}
		if (!Cap) {
			textRef.current.style = `text-transform: capitalize;font-size:${value}px;font-weight:${vari};`;
			CapBtn.current.style = "color:grey; text-decoration:underline;";
			NonCapBtn.current.style = "color:white;";
		}
	}, [Cap, value, vari]);

	useEffect(() => {
		CardRef.current.style = `font-family: '${props.fontName}', cursive;`;
	}, [props.fontName]);

	useEffect(() => {
		textRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive; font-size:${value}px; `;
	}, [value, vari, props.fontName]);

	const handleVari = (e) => {
		setVari(e.target.value);
		CardRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive`;
	};

	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
		textRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive; font-size:${value}px;`;
	};

	const handleAlignLeft = () => {
		textRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive; font-size:${value}px;text-align:left;`;
	};
	const handleAlignCenter = () => {
		textRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive; font-size:${value}px;text-align:center;`;
	};

	return (
		<div
			className="CardBody"
			ref={CardRef}
		>
			<div className="CardHeader">
				<select onChange={handleVari}>
					{props.fontVarients.map((varient, index) => {
						return (
							<option
								key={index}
								value={varient}
							>
								{props.fontName} {varient}
							</option>
						);
					})}
				</select>
				<button onClick={handleAlignLeft}>
					<FaAlignLeft />
				</button>
				<button onClick={handleAlignCenter}>
					<FaAlignCenter />
				</button>
				<button
					ref={CapBtn}
					onClick={handleCapBtn}
				>
					AA
				</button>
				<button
					ref={NonCapBtn}
					onClick={handleNonCapBtn}
				>
					Aa
				</button>
				<label>
					<AiOutlineArrowsAlt fontSize={30} />
					<Slider
						value={typeof value === "number" ? value : 15}
						onChange={handleSliderChange}
						aria-labelledby="input-slider"
						min={props.minSize}
						max={props.maxSize}
						defaultValue={props.defaultSize}
						step={1}
						sx={{ color: "#ffffff" }}
					/>
					<span>{value}px</span>
				</label>
			</div>
			<div
				className="CardText"
				contentEditable="true"
			>
				<p
					className="txt"
					ref={textRef}
				>
					{props.text}
				</p>
			</div>
		</div>
	);
}

export default FontCard2;
