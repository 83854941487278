import React from "react";
// import "./3DTypo.scss";
import { extend } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import Noir from "../../NoirPro/Noir Pro_Regular.json";
extend({ TextGeometry });

function TDTypo(props) {
	const font = new FontLoader().parse(Noir);
	const textOptions = {
		font: font,
		size: 0.5,
		height: 0.1,
		curveSegments: 5,
		bevelEnabled: false,
		bevelThickness: 0.1,
		bevelSize: 0.01,
		bevelOffset: 0,
		bevelSegments: 10,
	};
	return (
		<>
			<mesh
				castShadow
				position={props.position}
				scale={props.scale}
				rotation-x={props.rotationx}
				rotation-y={props.rotationy}
				rotation-z={props.rotationz}
			>
				<textGeometry
					attach="geometry"
					args={[props.letter, textOptions]}
				/>
				<meshBasicMaterial
					attach="material"
					color={`#${props.col}`}
				/>
			</mesh>
		</>
	);
}

export default TDTypo;
