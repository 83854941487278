import React from "react";

import { extend, useThree } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import Comfortaa from "../../Comfortaa/Comfortaa Medium_Regular.json";
import { useSphere } from "@react-three/cannon";
extend({ TextGeometry });

function TDTypo(props) {
	const { viewport } = useThree();
	const [ref] = useSphere((index) => ({
		mass: 10,
		position: [4 - Math.random() * 8, viewport.height, 0, 0],
		args: [3],
	}));

	const font = new FontLoader().parse(Comfortaa);
	const textOptions = {
		font: font,
		size: 4,
		height: 0.4,
		curveSegments: 5,
		bevelEnabled: false,
		bevelThickness: 0.1,
		bevelSize: 0.01,
		bevelOffset: 0,
		bevelSegments: 10,
	};
	return (
		<>
			<mesh
				castShadow
				ref={ref}
				// position={[props.count, 10, 0]}
			>
				<textGeometry
					attach="geometry"
					args={[props.letter, textOptions]}
				/>
				<meshStandardMaterial
					attach="material"
					color="red"
				/>
			</mesh>
		</>
	);
}

export default TDTypo;
