import React, { useEffect, useRef, useState } from "react";
import "./FontCard.scss";
import { Link } from "react-router-dom";

function FontCard(props) {
	const [Cap, setCap] = useState(true);
	const [vari, setVari] = useState(100);
	const CapBtn = useRef();
	const NonCapBtn = useRef();
	const textRef = useRef();
	const CardRef = useRef();

	const handleCapBtn = () => {
		setCap(true);
	};
	const handleNonCapBtn = () => {
		setCap(false);
	};
	useEffect(() => {
		if (Cap) {
			textRef.current.style = "text-transform: uppercase;";
			CapBtn.current.style = "color:white;";
			NonCapBtn.current.style = "color:grey; text-decoration:underline;";
		}
		if (!Cap) {
			textRef.current.style = "text-transform: capitalize;";
			CapBtn.current.style = "color:grey; text-decoration:underline;";
			NonCapBtn.current.style = "color:white;";
		}
	}, [Cap]);

	useEffect(() => {
		if (props.fontName === "NoirPro") {
			CardRef.current.style = "font-family: 'NoirPro', cursive;";
		}
		if (props.fontName === "Cairo") {
			CardRef.current.style = "font-family: 'Cairo', cursive;";
		}
		if (props.fontName === "Orbitron") {
			CardRef.current.style = "font-family: 'Orbitron', cursive;";
		}
	}, [props.fontName]);

	const handleVari = (e) => {
		setVari(e.target.value);
		CardRef.current.style = `font-weight:${vari}; font-family:${props.fontName},cursive`;
	};

	return (
		<div
			className="CardBody"
			ref={CardRef}
		>
			<div className="CardHeader">
				<select onChange={handleVari}>
					{props.fontVarients.map((varient, index) => {
						return (
							<option
								key={index}
								value={varient}
							>
								{props.fontName} {varient}
							</option>
						);
					})}
				</select>
				<div>
					<button
						ref={CapBtn}
						onClick={handleCapBtn}
					>
						AA
					</button>
					<button
						ref={NonCapBtn}
						onClick={handleNonCapBtn}
					>
						Aa
					</button>
				</div>
				<div>
					<button className="exp">
						<Link to={props.Link}>Explore</Link>
					</button>
				</div>
			</div>
			<div
				className="CardText"
				contentEditable="true"
			>
				<p
					id="txt"
					ref={textRef}
				>
					{props.text}
				</p>
			</div>
		</div>
	);
}

export default FontCard;
