import "./App.scss";
import React, { createContext, useState } from "react";
import Header from "./components/Header/Header";
import Homepage from "./components/Homepage/Homepage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NoirProPage from "./components/NoirProPage/NoirProPage";
import OrbitronPage from "./components/OrbitronPage/OrbitronPage";
import CairoPage from "./components/CairoPage/CairoPage";

export const MyContext = createContext();

function App() {
	const [theme, setTheme] = useState("dark");

	const handleThemeLight = () => {
		setTheme("light");
	};
	const handleThemeDark = () => {
		setTheme("dark");
	};
	return (
		<>
			<MyContext.Provider value={{ theme, handleThemeLight, handleThemeDark }}>
				<BrowserRouter>
					<Header />
					<Routes>
						<Route
							path="/"
							exact
							element={<Homepage />}
						/>
						<Route
							path="/NoirPro"
							exact
							element={<NoirProPage />}
						/>
						<Route
							path="/Orbitron"
							exact
							element={<OrbitronPage />}
						/>
						<Route
							path="/Cairo"
							exact
							element={<CairoPage />}
						/>
					</Routes>
				</BrowserRouter>
			</MyContext.Provider>
		</>
	);
}

export default App;
