import React, { useEffect } from "react";
import "./CairoPage.scss";
import { Canvas } from "@react-three/fiber";
import OutLine from "../OutLine/OutLine";
import FontCard2 from "../FontCard2/FontCard2";

function CairoPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
            in place of 'smooth' */
		});
	});
	const width = window.innerWidth;
	const handleDClick = () => {
		if (width <= 425) {
			window.scrollTo({
				top: 400,
				behavior: "smooth",
			});
		}
	};
	return (
		<div id="CairoMain">
			<div className="canvasContainer">
				<Canvas
					shadows
					onDoubleClick={handleDClick}
				>
					<OutLine />
				</Canvas>
				<div id="DblTp">double tap to scroll down</div>
			</div>
			{fonts.map((font, index) => {
				return (
					<FontCard2
						key={index}
						fontName={font.font}
						fontVarients={font.varients}
						text={font.text}
						defaultSize={font.defaultSize}
						minSize={font.minSize}
						maxSize={font.maxSize}
					/>
				);
			})}
		</div>
	);
}

export default CairoPage;
const fonts = [
	{
		font: "Cairo",
		varients: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
		text: "We Make Cool Shit That Performs",
		defaultSize: 63,
		minSize: 15,
		maxSize: 76,
	},
	{
		font: "Cairo",
		varients: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed ligula sollicitudin, egestas eros vitae, facilisis sapien. Nam finibus lobortis dui a elementum. Suspendisse mi eros, blandit vitae tortor et, porttitor finibus nisi. Etiam sollicitudin ipsum quis tempor elementum. Suspendisse potenti. Nulla finibus felis efficitur ultricies iaculis. Maecenas pulvinar aliquam massa. Ut egestas tincidunt sapien, eu suscipit justo laoreet et. Fusce luctus eleifend molestie. Proin eget urna sit amet justo pulvinar vehicula eget sed augue. Vivamus vel congue augue, sit amet euismod elit. Sed elementum leo non metus posuere efficitur ac at tortor. Suspendisse neque dui, elementum sit amet consectetur sit amet, vehicula nec ligula. Donec dignissim, arcu vel dapibus facilisis, arcu urna mollis est, ac feugiat lacus purus sit amet orci.",
		defaultSize: 21,
		minSize: 12,
		maxSize: 25,
	},
	{
		font: "Cairo",
		varients: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
		text: "either information produces meaning (a negentropic factor), but cannot make",
		defaultSize: 32,
		minSize: 12,
		maxSize: 39,
	},
];
