import React from "react";

import { extend } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import Orbitron from "../../Orbitron/Orbitron_Regular.json";
import { Center /*Float, MeshTransmissionMaterial */ } from "@react-three/drei";

extend({ TextGeometry });

function TDTypo(props) {
	const font = new FontLoader().parse(Orbitron);
	const textOptions = {
		font: font,
		size: 1,
		height: 0.1,
		curveSegments: 10,
		bevelEnabled: false,
		bevelThickness: 1,
		bevelSize: 0.1,
		bevelOffset: 0.2,
		bevelSegments: 20,
	};
	return (
		<>
			{/* <Float
				speed={1} // Animation speed, defaults to 1
				rotationIntensity={0} // XYZ rotation intensity, defaults to 1
				floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
				floatingRange={[0, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
			> */}
			<group
				// ref={letter}
				position={props.position}
				rotation-x={props.rx}
				rotation-y={props.ry}
				rotation-z={props.rz}
			>
				<Center
					top
					center
				>
					<mesh
						castShadow
						receiveShadow
					>
						<textGeometry
							attach="geometry"
							args={[props.letter, textOptions]}
						/>
						{/* <MeshTransmissionMaterial
							samples={32}
							resolution={2048}
							transmission={1}
							roughness={0}
							thickness={2}
							ior={2}
							chromaticAberration={0}
							anisotropy={0}
							distortionScale={0}
							distortion={0}
							temporalDistortion={0}
							clearcoat={0}
							attenuationColor={"#dbcfff"}
							attenuationDistance={0.5}
							color={"#dbcfff"}
							attach={"material"}
						/> */}
						<meshBasicMaterial color={"white"} />
					</mesh>
				</Center>
			</group>
			{/* </Float> */}
		</>
	);
}

export default TDTypo;
