import { Box, Modal } from "@mui/material";
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight";
import React from "react";
import "./OffCanvas.css";
import { Link } from "react-router-dom";
import Navi from "./Navi";

function OffCanvas(props) {
	return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="modal"
			aria-describedby="off canvas"
		>
			<Box
				className="OffCanvasBox"
				sx={style}
				// aria-labelledby="modalBody"
				// aria-describedby="Off Canvas Body"
			>
				<Link
					to={"/"}
					id="toHome"
				>
					Home
				</Link>
				<h3 className="header3">
					<BsArrowReturnRight className="Arrow" />
					TYPEFACES
				</h3>
				<div className="linkList">
					<Navi />
				</div>
				{/* <h3 className="header3">
					<BsArrowReturnRight className="Arrow" />
					THEME
				</h3> */}
				{/* <div className="btns">
					<button
						className="lightBtn"
						onClick={props.handleThemeLight}
					></button>
					<button
						className="darkBtn"
						onClick={props.handleThemeDark}
					></button>
				</div> */}
			</Box>
		</Modal>
	);
}

export default OffCanvas;

const style = {
	position: "absolute",
	top: "0",
	right: "0",
	width: 150,
	height: "100vh",
	bgcolor: "black",
	borderLeft: "1px solid white",
	p: 4,
	pt: "5rem",
};
