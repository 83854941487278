import React from "react";

import { extend } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import Cairo from "../../Cairo/Cairo_Regular.json";
import { Float /**  MeshTransmissionMaterial*/ } from "@react-three/drei";

extend({ TextGeometry });

function TDTypo(props) {
	const font = new FontLoader().parse(Cairo);
	const textOptions = {
		font: font,
		size: 0.25,
		height: 0.1,
		curveSegments: 10,
		bevelEnabled: false,
		bevelThickness: 0.1,
		bevelSize: 0.01,
		bevelOffset: 0,
		bevelSegments: 10,
	};
	return (
		<>
			<Float
				speed={1} // Animation speed, defaults to 1
				rotationIntensity={1} // XYZ rotation intensity, defaults to 1
				floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
				floatingRange={[1, 1.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
			>
				<mesh
					castShadow
					position={props.position}
					receiveShadow
				>
					<textGeometry
						attach="geometry"
						args={[props.letter, textOptions]}
					/>
					{/* <MeshTransmissionMaterial
						samples={32}
						resolution={2048}
						transmission={1}
						roughness={0}
						thickness={2}
						ior={2}
						chromaticAberration={0}
						anisotropy={0}
						distortionScale={0}
						distortion={0}
						temporalDistortion={0}
						clearcoat={0}
						attenuationColor={"#dbcfff"}
						attenuationDistance={0.5}
						color={"#dbcfff"}
						attach={"material"}
					/> */}
					<meshStandardMaterial color={"white"} />
				</mesh>
			</Float>
		</>
	);
}

export default TDTypo;
