import React, { useEffect } from "react";
import "./Homepage.scss";
import { Canvas } from "@react-three/fiber";
import MouseMove from "../MouseMove/MouseMove";
import FontCard from "../FontCard/FontCard";
// import Glass from "../Glass/Glass";
// import OutLine from "../OutLine/OutLine";
// import SlimeCube from "../SlimeCube/SlimeCube";
// import Spheres from "../Spheres/Sphere";

function Homepage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
            in place of 'smooth' */
		});
	});

	return (
		<div id="homeMain">
			<div className="canvasContainer">
				<Canvas
					shadows
					gl={{ stencil: false, antialias: false }}
					camera={{ position: [0, 0, 20], fov: 50, near: 1, far: 40 }}
				>
					<MouseMove />
				</Canvas>
			</div>
			{/* <div className="canvasContainer">
				<Canvas shadows>
					<Spheres />
				</Canvas>
			</div>*/}
			<div id="sec1">
				<p>
					WE CREATED A DISTINGUISHED & EVER-EVOLVING CATALOG OF TYPEFACES — EVERY GLYPH HAS BEEN CAREFULLY CRAFTED WITH
					OBSESSIVE ATTENTION TO DETAIL, READY TO USE ON YOUR NEXT BIG PROJECT.
				</p>
				<span>
					©{" "}
					<a
						href="https://stitchesstudios.com"
						target="_blank"
						rel="noreferrer"
					>
						STITCHES STUDIOS
					</a>{" "}
					LLC 2023
				</span>
			</div>

			<p id="check">CHECK THEM ALL, DOWNSTAIRS.</p>
			{fonts.map((font, index) => {
				return (
					<FontCard
						key={index}
						fontName={font.font}
						fontVarients={font.varients}
						text={font.text}
						Link={font.Link}
					/>
				);
			})}
		</div>
	);
}

export default Homepage;

const fonts = [
	{
		font: "NoirPro",
		varients: [400, 500, 600, 700, 800, 900],
		text: "Stitches Studios",
		Link: "/NoirPro",
	},
	{
		font: "Orbitron",
		varients: [400, 500, 600, 700, 800, 900],
		text: "Stitches Studios",
		Link: "/Orbitron",
	},
	{
		font: "Cairo",
		varients: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
		text: "Stitches Studios",
		Link: "/Cairo",
	},
];
