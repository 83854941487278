import React, { useEffect, useState } from "react";
import { Particles } from "./Particles";
import { CameraShake, PerspectiveCamera } from "@react-three/drei";
import TDTypo from "./TDTypo";
import { useFrame } from "@react-three/fiber";
function Glass() {
	const [foc, setFoc] = useState(0.5);
	const [fov, setFov] = useState(15);
	const props = {
		focus: 6.07,
		speed: 26.2,
		aperture: 3.9,
		fov: 44,
		curl: 0.29,
	};
	useFrame(({ mouse }) => {
		const x = (mouse.x + 1) / 2;
		setFoc(x);
	});
	useEffect(() => {
		const width = window.innerWidth;
		if (width <= 425) {
			setFov(30);
		}
	}, []);
	return (
		<>
			<PerspectiveCamera
				makeDefault
				fov={fov}
				position={[0, 0, 5 + foc * 1.5]}
			/>
			{/* <OrbitControls
				makeDefault
				// autoRotate
				// autoRotateSpeed={0.5}
				zoomSpeed={0.1}
			/> */}
			<CameraShake
				yawFrequency={1}
				maxYaw={0.05}
				pitchFrequency={1}
				maxPitch={0.05}
				rollFrequency={0.5}
				maxRoll={0.5}
				intensity={0.2}
			/>
			<Particles {...props} />
			<TDTypo
				letter={"SS"}
				position={[0, -0.5, -7]}
				rx={0}
				ry={0}
				rz={0}
			/>
		</>
	);
}

export default Glass;
