import React from "react";
import { Link } from "react-router-dom";

function Navi() {
	return (
		<>
			<Link to={"/NoirPro"}>NOIR PRO</Link>
			<Link to={"/Orbitron"}>ORBITRON</Link>
			<Link to={"/Cairo"}>Cairo</Link>
		</>
	);
}

export default Navi;
