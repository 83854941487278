import React, { useEffect, useRef, useState } from "react";
import {
	Environment,
	FlyControls,
	Gltf /*, OrbitControls, Sparkles ,Stars*/,
	// Html,
	OrbitControls,
	PerspectiveCamera,
} from "@react-three/drei";
// import { useFrame } from "@react-three/fiber";
// import { Vector3 } from "three";
import TDTypo from "./TDTypo";
import { useFrame, useThree } from "@react-three/fiber";
// import { Vector3 } from "three";

function OutLine() {
	// const planeRef = useRef();
	// const camRef = useRef();
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
	const cameraRef = useRef();
	const { size } = useThree();
	// const [isCanvasHovered, setIsCanvasHovered] = useState(true);
	const [mob, setMob] = useState(false);

	useEffect(() => {
		const width = window.innerWidth;
		if (width <= 425) {
			return;
		}
		const handleMouseMove = (event) => {
			const { clientX, clientY } = event;
			const { width, height } = windowSize;
			const mousePositionX = (clientX / width) * 2 - 1;
			const mousePositionY = -(clientY / height) * 2 + 1;
			setMousePosition({ x: mousePositionX, y: mousePositionY });
		};

		const handleResize = () => {
			setWindowSize({ width: window.innerWidth, height: window.innerHeight });
		};

		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("resize", handleResize);
		};
	}, [windowSize]);

	const handleMoveStart = () => {
		document.body.style.cursor = "none"; // Hide the cursor when moving
	};

	const handleMoveEnd = () => {
		document.body.style.cursor = "auto"; // Show the cursor when movement ends
	};

	useFrame(({ camera }) => {
		const { x, y } = mousePosition;
		const rotationY = Math.max(Math.min(x * (Math.PI / 2), Math.PI / 2), -Math.PI / 2); // Limit rotation around the Y-axis
		const rotationX = Math.max(Math.min(-y * (Math.PI / 6), Math.PI / 6), -Math.PI / 6); // Limit rotation around the X-axis
		camera.rotation.y = -rotationY;
		camera.rotation.x = -rotationX;
		const width = window.innerWidth;
		if (width <= 425) {
			cameraRef.current.lookAt(0, 1, 0);
		}
	});

	useEffect(() => {
		const width = window.innerWidth;
		if (width <= 425) {
			setMob(true);
		}
	}, []);

	return (
		<>
			{/* <PerspectiveCamera
				ref={cameraRef}
				position={[0, 2, 3]}
				fov={50}
				makeDefault
				near={0.01}
				far={100}
			/>
			<FlyControls
				autoForward={false}
				dragToLook={false}
				movementSpeed={1}
				rollSpeed={0}
				makeDefault
			/> */}

			{mob ? (
				<>
					<PerspectiveCamera
						ref={cameraRef}
						makeDefault
						position={[0, 1.5, 3]}
						fov={80}
						aspect={size.width / size.height}
						near={0.1}
						far={100}
					/>
					<OrbitControls />
					{/* <Html>
						<p id="DblTp">Double Tap to scroll down</p>
					</Html> */}
				</>
			) : (
				<>
					<PerspectiveCamera
						ref={cameraRef}
						makeDefault
						position={[0, 2, 3]}
						fov={60}
						aspect={size.width / size.height}
						near={0.1}
						far={100}
					/>
					<FlyControls
						movementSpeed={1}
						rollSpeed={0}
						dragToLook={false}
						onStart={handleMoveStart}
						onStop={handleMoveEnd}
					/>
				</>
			)}

			{/* <Gltf
				ref={planeRef}
				src="/assets/Models/plane.glb"
				position={[0, 1.85, 2.97]}
				rotation-y={Math.PI}
				scale={[0.05, 0.05, 0.05]}
				receiveShadow
				castShadow
			/> */}
			{/* <OrbitControls
				enablePan={false}
				enableZoom={false}
				// minAzimuthAngle={-Math.PI / 4}
				// maxAzimuthAngle={Math.PI / 4}
				minPolarAngle={Math.PI - Math.PI / 1.1}
				maxPolarAngle={Math.PI - Math.PI / 1.7}
			/> */}

			<pointLight position={[0, 0, 0]} />
			<pointLight position={[0, 0, 0]} />
			<color
				attach={"background"}
				args={["#bf7129"]}
			/>
			<Environment
				background={true} // can be true, false or "only" (which only sets the background) (default: false)
				blur={1} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
				files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/goegap_1k.hdr"
			/>
			<Gltf
				src="/assets/Models/Cairo.glb"
				position={[0, 0, 0]}
				receiveShadow
				castShadow
			/>

			<TDTypo
				letter={"S"}
				position={[-0.5, 0, 1.5]}
			/>
			<TDTypo
				letter={"S"}
				position={[0.1, -0.25, 1.5]}
			/>
			<fog
				attach={"fog"}
				near={1}
				far={17}
				color={"#ff9636"}
			/>
		</>
	);
}

export default OutLine;
