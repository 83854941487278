import { useRef, useState, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { Color } from "three";
import { RoundedBox, Text } from "@react-three/drei";

export default function Box({ text, ...props }) {
	const ref = useRef();
	const black = useMemo(() => new Color("black"), []);
	const blue = useMemo(() => new Color("#ff6200"), []);
	const [hovered, setHovered] = useState(false);

	useFrame(({ mouse, viewport }) => {
		const x = (mouse.x * viewport.width) / 2.5;
		const y = (mouse.y * viewport.height) / 2.5;
		ref.current.lookAt(x, y, 1);
		ref.current.material.color.lerp(hovered ? blue : black, 0.05);
	});

	return (
		// <mesh
		// 	{...props}
		// 	ref={ref}
		// 	onPointerOver={() => setHovered(true)}
		// 	onPointerOut={() => setHovered(false)}
		// >
		// 	<dec args={[1, 1, 1]} />
		// 	<meshStandardMaterial color={blue} />
		// 	<Text
		// 		fontSize={0.5}
		// 		position-z={0.501}
		// 	>
		// 		{text}
		// 	</Text>
		// 	{props.children}
		// </mesh>
		<RoundedBox
			{...props}
			ref={ref}
			onPointerOver={() => setHovered(true)}
			onPointerOut={() => setHovered(false)}
			args={[0.5, 0.5, 0.5]} // Width, height, depth. Default is [1, 1, 1]
			radius={0.1} // Radius of the rounded corners. Default is 0.05
			smoothness={4} // The number of curve segments. Default is 4
			creaseAngle={0.4}
		>
			<meshStandardMaterial color={blue} />
			<Text
				font="../../NoirPro/NoirPro-Regular.ttf"
				fontSize={0.25}
				position-z={0.251}
			>
				{text}
			</Text>
			{props.children}
		</RoundedBox>
	);
}
